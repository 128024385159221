import { createXApiKey } from "~/infra/http/XApiKey";

export const useXApiKey = () => {
  const xApiKey = useCookie("x-api-key", {
    default: () => createXApiKey().generateToken(),
    maxAge: 300,
    watch: true,
  });

  function refresh() {
    xApiKey.value = createXApiKey().generateToken();
  }

  return {
    xApiKey,
    refresh,
  };
};
